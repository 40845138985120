<template>
<el-form v-loading="loading1">
  <div v-if="panelForm.devBrandId == 30">
                    <el-row style="margin-bottom:15px">
                        <el-button @click="faceSnapFun" type="primary">人脸授权</el-button>
                        <el-button @click="haveAuthorized" type="primary">已授权人员</el-button>&nbsp;
                        <el-popover placement="top" width="250" v-model="faceSnap.emptyFace">
                            <p>确定要清空该设备脸库吗?清空后将无法恢复。</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="faceSnap.emptyFace = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="emptyFaceConfirm">确定</el-button>
                            </div>
                            <el-button type="primary" slot="reference">清空脸库</el-button>
                        </el-popover>&nbsp;
                        <el-button type="primary" @click="equipmentCorrect">设备校时</el-button>
                    </el-row>
                    <el-row style="margin-bottom:15px">
                        <el-popover placement="top" width="300" title="设备升级" v-model="faceSnap.equipmentUpgrades">
                            <div style="margin-bottom: 10px;">
                                <el-input v-model="faceSnap.upgradeUrl" placeholder="请输入升级包地址" size="mini"></el-input>
                            </div>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="faceSnap.equipmentUpgrades = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="upgradesConfirm">升级</el-button>
                            </div>
                            <el-button slot="reference" type="primary">设备升级</el-button>
                        </el-popover>&nbsp;
                        <el-button @click="captureRecordsFun" type="primary">抓拍记录</el-button>&nbsp;
                        <el-popover placement="top" width="300" title="服务器地址修改" v-model="faceSnap.equipmentServe">
                            <div style="margin-bottom: 10px;">
                                <el-input v-model="faceSnap.serveUrl" placeholder="请输入服务器地址" size="mini"></el-input>
                            </div>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="faceSnap.equipmentServe = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="serverUrlFun">修改</el-button>
                            </div>
                            <el-button slot="reference" type="primary">服务器地址修改</el-button>
                        </el-popover>
                    </el-row>
                </div>
                <div v-else-if="panelForm.devBrandId == 33 || panelForm.devBrandId == 34">
                    <el-row style="margin-bottom:15px">
                        <el-button @click="faceSnapFun" type="primary">人脸授权</el-button>
                        <el-button @click="haveAuthorized" type="primary">已授权人员</el-button>&nbsp;
                        <el-popover placement="top" width="250" v-model="faceSnap.emptyFace">
                            <p>确定要清空该设备脸库吗?清空后将无法恢复。</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="faceSnap.emptyFace = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="emptyFaceConfirm">确定</el-button>
                            </div>
                            <el-button 
                                type="primary" 
                                slot="reference" 
                                v-if="[120,121,122,123,124,125,126].indexOf(panelForm.devSecondType) === -1"
                            >清空脸库
                            </el-button>
                        </el-popover>&nbsp;
                        <el-button type="primary" @click="equipmentCorrect">设备校时</el-button>
                        <el-button type="primary" @click="modifyPushAddress">服务器地址设置</el-button>
                        <el-button type="danger" @click="deleteFaceDevice">删除设备</el-button>
                    </el-row>
                    <el-row style="margin-bottom:15px">
                        <el-popover
                            placement="top"
                            width="300"
                            title="设备升级"
                            v-model="faceSnap.equipmentUpgrades"
                        >
                            <div style="margin-bottom: 10px;">
                                <el-input v-model="faceSnap.upgradeUrl" placeholder="请输入升级包地址" size="mini"></el-input>
                            </div>
                            <div style="text-align: right; margin: 0">
                                <el-button
                                    size="mini"
                                    type="text"
                                    @click="faceSnap.equipmentUpgrades = false"
                                >取消</el-button>
                                <el-button type="primary" size="mini" @click="upgradesConfirm">升级</el-button>
                            </div>
                            <el-button 
                                slot="reference" 
                                type="primary" 
                                v-if="[120,121,122,123,124,125,126].indexOf(panelForm.devSecondType) === -1"
                                >设备升级
                            </el-button>
                        </el-popover>&nbsp;
                        <el-button @click="captureRecordsFun" type="primary">抓拍记录</el-button>&nbsp;
                        <el-popover
                            placement="top"
                            width="300"
                            title="服务器地址修改"
                            v-model="faceSnap.equipmentServe"
                        >
                            <div style="margin-bottom: 10px;">
                                <el-input v-model="faceSnap.serveUrl" placeholder="请输入服务器地址" size="mini"></el-input>
                            </div>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="faceSnap.equipmentServe = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="serverUrlFun">修改</el-button>
                            </div>
                            <el-button 
                                slot="reference" 
                                type="primary" 
                                v-if="[120,121,122,123,124,125,126].indexOf(panelForm.devSecondType) === -1"
                            >服务器地址修改
                            </el-button>
                        </el-popover>
                        <el-button
                            v-if="[120,121,122].indexOf(panelForm.devSecondType) === -1"
                            type="primary"
                            @click="remoteDoorOpening"
                        >远程开门</el-button>
                    </el-row>
                </div>
                <div v-else>
                    <el-row style="margin-bottom:15px">
                        <el-button @click="faceSnapFun" type="primary">人脸授权</el-button>
                        <el-button @click="haveAuthorized" type="primary">已授权人员</el-button>
                        <el-button @click="captureRecordsFun" type="primary">抓拍记录</el-button>
                        <el-button type="danger" @click="deleteFaceDevice">删除设备</el-button>
                        <el-button
                            v-if="panelForm.devBrandId == 32"
                            type="primary"
                            @click="equipmentCorrect"
                        >设备校时</el-button>
                        <el-button
                            v-if="panelForm.devBrandId == 32"
                            type="primary"
                            @click="remoteDoorOpening"
                        >远程开门</el-button>
                    </el-row>
                </div>
                <div v-if="panelForm.devFirstType == 33">
                    <el-row style="margin-bottom:15px">
                        <el-button type="primary" @click="BlackListDialog">黑名单</el-button>
                    </el-row>
                </div>
                <!-- 人脸抓拍 -->
                <el-dialog 
                    v-dialogDrag title="选择授权人员" 
                    v-loading="loading1" 
                    :visible.sync="faceSnap.faceDialogs" 
                    width="900px" 
                    :close-on-click-modal="false" 
                    append-to-body
                >
                    <el-row class="transferStyle">
                        <!--穿梭框-->
                        <el-col>
                            <el-transfer 
                                :data="faceSnap.personnelData" 
                                v-model="faceSnap.checkedFace" 
                                :titles="faceSnap.equipmentTitle" 
                                :props="{key: 'ifpId',label: 'ifpName'}" 
                                filterable 
                                filter-placeholder="搜索授权人员"
                            ></el-transfer>
                        </el-col>
                    </el-row>
                    <div slot="footer">
                        <el-button @click="faceSnap.faceDialogs = false">取 消</el-button>
                        <el-button type="primary" @click="faceSnapSave">保 存</el-button>
                    </div>
                </el-dialog>
                <!-- 已授权人员 -->
                <el-dialog 
                    v-dialogDrag 
                    title="选择授权人员" 
                    v-loading="loading1" 
                    :visible.sync="faceSnap.haveAuthorizedDialogs" 
                    width="600px" 
                    :close-on-click-modal="false" 
                    append-to-body
                >
                    <el-table :data="faceSnap.haveAuthorizedData" style="width: 100%">
                        <el-table-column prop="ifpName" label="姓名" align="center"></el-table-column>
                        <el-table-column prop="ifpPersonType" label="人员类型" align="center"></el-table-column>
                        <el-table-column prop="ifpdStatus" label="状态" align="center"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button @click="deleteFaceAuthorization(scope.row)" size="mini" type="danger">删除授权</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-dialog>
                <!-- 抓拍记录 -->
                <el-dialog 
                    v-dialogDrag 
                    title="抓拍记录" 
                    @close="snapClose" 
                    v-loading="loading1" 
                    :visible.sync="faceSnap.dialogCaptureRecords" 
                    :close-on-click-modal="false" 
                    width="800px" 
                    append-to-body
                >
                    <el-form>
                        <el-form-item>
                            人员：
                            <el-input 
                                @change="captureRecordsFun" 
                                v-model="faceSnap.jftiPersonName" 
                                style="width:150px" 
                                size="mini" 
                                clearable>
                            </el-input>&nbsp;&nbsp;
                            时间：
                            <el-date-picker 
                                v-model="faceSnap.time" 
                                type="daterange" 
                                align="right" 
                                size="mini" 
                                unlink-panels 
                                range-separator="至" 
                                start-placeholder="开始日期" 
                                end-placeholder="结束日期" 
                                @change="captureRecordsFun" 
                                value-format="yyyy-MM-dd" 
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <el-table :data="faceSnap.captureRecordsData" highlight-current-row>
                        <el-table-column prop="jftiPasernType" label="人员类型" min-width="80px"></el-table-column>
                        <el-table-column prop="jftiPersonName" label="人员" min-width="80px"></el-table-column>
                        <el-table-column prop="deviceName" label="设备名称" min-width="80px"></el-table-column>
                        <el-table-column prop="jftiRecMode" label="识别模式" min-width="100px"></el-table-column>
                        <el-table-column prop="jfriShowTime" label="识别记录时间" min-width="160px"></el-table-column>
                        <el-table-column label="抓拍照片" min-width="40px">
                            <template slot-scope="scope">
                                <img 
                                    :src="scope.row.jftiPhotoUrl" 
                                    alt="" 
                                    style="width:40px" 
                                    @click.self="imgShow(scope.row.jftiPhotoUrl)"
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-dialog v-dialogDrag :visible.sync="dialogImg" :close-on-click-modal="false" append-to-body>
                        <img width="400px" :src="dialogImageUrl" alt="" style="display:block;margin:0 auto">
                    </el-dialog>
                    <el-pagination 
                        layout="prev, pager, next, total" 
                        :total="faceSnap.total" 
                        :page-size="faceSnap.limit" 
                        :page-count="faceSnap.pages" 
                        :current-page="faceSnap.cursor" 
                        @current-change="snapCurrent" 
                        align="center" 
                        background 
                        style="margin-top:5px">
                    </el-pagination>
                </el-dialog>
                <!-- 黑名单 -->
                <el-dialog
                    title="黑名单授权列表"
                    v-loading="loading1"
                    :visible.sync="blackList.dialog"
                    width="800px"
                   :close-on-click-modal="false" 
                    append-to-body>
                     <el-button type="primary"  @click="addBlackListDialog">添加黑名单</el-button>
                    <el-table :data="blackList1.params" height="400"  style="width: 100%">
                        <el-table-column prop="ifpName" label="姓名" align="center"></el-table-column>
                        <el-table-column prop="ifpPersonType" label="人员类型" align="center"></el-table-column>
                        <el-table-column  label="人员照片" align="center" >
                            <!-- 图片预览 放大原图 -->
                            <template slot-scope="scope" >
                                <el-image style="width: 60px; height: 60px ;margin:10px"  :src="scope.row.image" @click.self="openPicture(scope.row.image)" fit="fit"></el-image>
                                <el-dialog :visible.sync="blackList1.dialogVisible" @close='enlargePictureCloseList' :close-on-click-modal="false" append-to-body> 
                                    <img width="100%" :src="blackList1.dialogImageUrl" alt="">
                                </el-dialog>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ifpdStatus" label="状态" align="center"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button  size="mini" @click='removeBlackUser(scope.row)' type="danger">删除授权</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- <el-pagination  //分页器
                        background
                        :page-sizes="[10,20,30,50,100]"
                        :page-size="blackList1.limit"
                        @size-change='blackListSizeChange'
                        layout="prev, pager, next,jumper,sizes"
                        :pager-count='7'
                        @current-change='blackListCurrentchange'
                        @prev-click='blackListPrevClick'
                        @next-click='blackListNextClick'
                        :page-count='blackList1.pages'
                        :total="blackList1.total">
                    </el-pagination> -->
                    <div slot="footer">
                    </div>
                </el-dialog>

                <el-dialog 
                    v-dialogDrag 
                    title="添加黑名单" 
                    v-loading="loading1" 
                    @close="addblackListClose" 
                    :visible.sync="blackList.dialog1" 
                    width="500px" 
                    :close-on-click-modal="false" 
                    append-to-body>
                    <el-steps :active="blackList.active" simple>
                        <el-step title="步骤 1" icon="el-icon-edit"></el-step>
                        <el-step title="步骤 2" icon="el-icon-picture"></el-step>
                        <el-step title="步骤 3" icon="el-icon-upload"></el-step>
                    </el-steps>
                    <el-form :model="blackList" ref="blackList" label-width="80px" style="margin-top:20px" v-show="blackList.active === 1">
                        <!-- <el-form-item label="员工姓名" prop="personnelName" v-if="blackList.staffType === '内部员工' " :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                            <el-input v-model="blackList.personnelName" size="mini" style="width:200px"></el-input>
                        </el-form-item>
                        <el-form-item label="电话号码" label-width='80px' v-if="blackList.staffType === '内部员工'" prop="telNumber" :rules="[{ required: true, pattern:/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/, message: '请输入正确的电话号码', trigger: 'blur' }]">
                            <el-input v-model="blackList.telNumber" size="mini" style="width:200px"></el-input>
                        </el-form-item> -->
                        <el-form-item label="员工类型">
                            <el-radio v-model="blackList.staffType" label="外部人员">外部人员</el-radio>
                            <el-radio v-model="blackList.staffType" label="内部员工" @change ="changeStaffList">内部员工</el-radio>
                        </el-form-item>
                        <el-dialog
                            title="选择添加黑名单的员工"
                            :visible.sync="blackList.staffListDialogVisible"
                            width="900px"
                            @close="changeStaffType"
                            :modal-append-to-body='true'
                            append-to-body>
                            <el-row class="transferStyle" :gutter="8">
                                <el-col>
                                    <el-transfer 
                                    :data="blackList.staffList" 
                                    v-model="blackList.checkedStaffList"
                                    :titles="blackList.staffListTitle"
                                    :props="{key:'ifpId',label:'ifpName'}"
                                    filterable filter-placeholder="搜索员工">
                                    </el-transfer>
                                </el-col>
                            </el-row>
                            <el-dialog
                                title=""
                                :visible.sync="blackList.dialogVisibleBlackStaff"
                                width="400px"
                                :before-close="cancelUploadBlackStaff"
                                append-to-body>
                                <h3>  确认将这些用户添加进黑名单吗?</h3>
                                <div slot="footer">
                                    <el-button @click="cancelUploadBlackStaff">取 消</el-button>
                                    <el-button type="primary" @click="uploadBlackStaffList ">确 定</el-button>
                                </div>
                            </el-dialog>
                            <div slot="footer">
                                <el-button @click="changeStaffType">取 消</el-button>
                                <el-button type="primary" @click="confirmUploadBlackStaffList">确 定</el-button>
                            </div>
                        </el-dialog>
                        <el-form-item label-width="92px" label="黑名单人员" prop="blackName" :rules="[{ required: true, message: '请输入黑名单人员名称', trigger: 'blur' }]">
                            <el-input placeholder="请输入黑名单成员的姓名(名称)"  v-model="blackList.blackName" size="mini" style="width:200px"></el-input>
                        </el-form-item>
                         <el-form-item label="性别">
                            <el-radio v-model="blackList.blackSex" label="男">男</el-radio>
                            <el-radio v-model="blackList.blackSex" label="女">女</el-radio>
                        </el-form-item>
                        <!-- 选择内部员工 -->
                        <!-- <el-form-item label="员工姓名"  v-if="blackList.staffType === '内部员工'" >
                            <el-select  size='mini' placeholder="选择员工" v-model="blackList.personnelName" @change='selectStaffList'>
                                <el-option
                                    v-for="(item,index) in blackList.staffList"
                                    :key="index"
                                    :lable="item.staffName"
                                    :value="item.staffName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="员工电话" v-if="blackList.staffType === '内部员工'">
                            <el-input v-model="blackList.telNumber" style="width:200px" size='mini' disabled ></el-input>
                        </el-form-item> -->
                        <el-form-item label="备注" prop="personnelAscription" :rules="[{min: 1,max: 30, message:'字数不能超过30!!',trigger: 'blur'}]" >
                            <el-input 
                                type="textarea"
                                :rows="2" 
                                v-model="blackList.personnelAscription" 
                                size="mini" 
                                style="width:200px"
                                placeholder="黑名单人员备注信息"
                                >
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-upload
                        v-show="blackList.active === 2"
                        style="margin:20px 80px"
                        class="avatar-uploader"
                        action=""
                        :file-list='blackList.base64'
                        list-type="picture-card"
                        ref="upload"
                        :auto-upload="false"
                        :on-change="getFile"
                        :limit="1" 
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        accept=".jpg,.png">
                        <!-- <img v-if="blackList.base64" :src="blackList.base64" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                        <div class="el-upload__tip" slot="tip">请上传黑名单人员照片</div>
                        <el-dialog :visible.sync="blackList.dialogVisible" @close='enlargePictureClose' :close-on-click-modal='true' :append-to-body='true' modal-append-to-body> 
                            <img width="100%" :src="blackList.base64[0]" alt="">
                        </el-dialog>
                    </el-upload>
                    <el-table  
                        style="width: 100%;margin-top:20px" 
                        :data="blackList.equipmentList" 
                        v-show="blackList.active === 3"
                        @selection-change="selectFaceDevice" 
                        ref="faceDeviceList">
                            <el-table-column type="selection" width="30"></el-table-column>
                            <el-table-column prop="idftName" label="枪机类型" width="80px">
                            </el-table-column>
                            <el-table-column prop="idstName" label="枪机型号" width="140px">
                            </el-table-column>
                            <el-table-column prop="devNickname" label="枪机名称" width='100px'>
                            </el-table-column>
                            <el-table-column prop="hsAddCommunity" label="安装地址" width='120px'>
                            </el-table-column>
                            <el-table-column prop="devSn" label="设备SN" width='120px'>
                            </el-table-column>
                    </el-table>
                    <div slot="footer">
                        <el-button @click="cancelUpload">取 消</el-button>
                        <el-button @click="addblackListPrevious" v-show="blackList.active > 1">上一步</el-button>
                        <el-button type="primary"  v-show="blackList.active === 3" @click='uploadFaceDevcieList'>保 存</el-button>
                        <el-button type="primary" @click="addblackListNext" v-show="blackList.active <3">下一步</el-button>
                    </div>
                </el-dialog>
                  
                </el-form>
</template>

<script>
export default {
  props: {
    form:{
        type:Object,
    },
    panelForm: {
      type: Object,
    },
    isShow :{
        type:Boolean,
        default: false
    },
    enabled :{
        type: Object,
        
    }
  },
  data(){
    return {
      //人脸抓拍
            faceSnap: {
                faceDialogs: false,
                personnelData: [],
                checkedFace: [],
                haveAuthorizedDialogs: false,
                haveAuthorizedData: [],
                emptyFace: false,
                equipmentUpgrades: false,
                upgradeUrl: '',
                serverUrl: '',
                equipmentServe: false,
                serveUrl: '',
                equipmentTitle: ['全部授权人员', '已选授权人员'],
                dialogCaptureRecords: false,
                cursor: 1,
                total: 0,
                pages: 0,
                limit: 5,
                captureRecordsData: [],
                jftiPersonName: '',
                time: [],
            },
            blackList: { //上传黑名单数据
                staffType: '外部人员',
                dialog: false, //黑名单列表
                dialog1: false, 
                personnelName: '',
                blackName:'',
                blackSex: '男',
                personnelAscription: '',
                active: 1,
                telNumber:'',
                base64:[],
                personnelId:'',
                staffListDialogVisible: false, //内部员工
                dialogVisible: false,  //图片放大
                staffList:[],
                checkedStaffList:[], //所选中的内部员工
                staffListTitle:["所有内部员工","已选择内部员工"],
                equipmentList:[],
                selectedFaceDevice:[], //枪机列表中被选中的设备
                dialogVisibleBlackStaff: false //上传内部员工确认
            },
            blackList1:{ //黑名单列表数据
                cursor: 1, //当前页面
                limit: 10, //每页个数
                total: 0, //总条数
                pages: 0, //总页数
                dialogVisible: false,
                dialogImageUrl:'',
                params:[],
            },
             pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            dialogCaoZuoSheBei: this.isShow,
            dialogImg: false,
            dialogImageUrl: '',
            modifyPush: { // 人脸枪击服务器地址修改
                dialog: false,
                url: '',
            },
            loading1:false
    }
  },
  created() {
    console.log(this.panelForm)
    console.log(this.form)
  },
  watch :{
   
  },
  methods :{
       faceSnapFun() { //人脸抓拍授权
            this.loading1 = true
            this.$axios({
                method: 'get',
                url: `/common2/devices/getUnauthorizedPersonnel/${this.form.clickrow.jourDeviceId}`
            }).then(res => {
                this.faceSnap.faceDialogs = true
                this.loading1 = false
                this.faceSnap.personnelData = res.data.result
                this.faceSnap.personnelData.forEach(item => {
                    item.ifpName = `${item.ifpName}----${item.ifpPersonType}`
                })
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        haveAuthorized() { //已授权人员
            this.loading1 = true
            this.$axios({
                method: 'get',
                url: `/common2/devices/getAuthorizedPersonnel/${this.form.clickrow.jourDeviceId}`
            }).then(res => {
                this.faceSnap.haveAuthorizedDialogs = true
                this.faceSnap.haveAuthorizedData = res.data.result
                this.loading1 = false
                console.log(this.faceSnap.checkedFace);
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        equipmentCorrect() { //设备校时
            this.loading1 = true
            this.$axios({
                method: 'post',
                url: `/common2/devices/calibrationTime`,
                params: {
                    deviceId: this.form.clickrow.jourDeviceId
                }
            }).then(res => {
                this.$message.success('校时成功')
                this.loading1 = false
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        upgradesConfirm() { //设备升级
            this.loading1 = true
            this.$axios({
                method: 'post',
                url: `/common2/devices/upgrade`,
                params: {
                    upgradeUrl: this.faceSnap.upgradeUrl
                }
            }).then(res => {
                this.$message.success('升级成功')
                this.loading1 = false
                this.faceSnap.equipmentUpgrades = false
            }).catch(err => {
                this.loading1 = false
                this.faceSnap.equipmentUpgrades = false
                this.isError(err, this)
            })
        },
        captureRecordsFun() { //获取抓拍记录数据
            this.faceSnap.time = this.faceSnap.time == null ? [] : this.faceSnap.time
            this.loading1 = true
            this.$axios({
                method: 'get',
                url: `/common2/faceRecognitionInformations/pageFaceCapture`,
                params: {
                    id: this.form.clickrow.jourDeviceId,
                    limit: this.faceSnap.limit,
                    cursor: this.faceSnap.cursor,
                    jftiPersonName: this.faceSnap.jftiPersonName,
                    startTime: this.faceSnap.time[0],
                    endTime: this.faceSnap.time[1]
                }
            }).then(res => {
                this.faceSnap.captureRecordsData = res.data.result.records
                this.faceSnap.dialogCaptureRecords = true
                this.faceSnap.total = res.data.result.total
                if (res.data.result.total === 0) return
                //判断第一个图片/视频加载失败更换备用地址
                let newImg = new Image()
                newImg.src = this.faceSnap.captureRecordsData[0].jftiPhotoUrl
                let timer = setTimeout(() => {
                    for (let i in this.faceSnap.captureRecordsData) {
                        this.faceSnap.captureRecordsData[i].jftiPhotoUrl = this.faceSnap.captureRecordsData[i].jftiStandbyPhotoUrl
                    }
                    console.log(this.faceSnap.captureRecordsData)
                }, 3000)
                newImg.onload = () => {
                    clearTimeout(timer)
                    timer = null;
                }
                this.loading1 = false
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        serverUrlFun() { //服务器地址修改
            this.loading1 = true
            this.$axios({
                method: 'post',
                url: `/common2/devices/serverAddressModification`,
                params: {
                    deviceId: this.form.clickrow.jourDeviceId,
                    serverUrl: this.faceSnap.serveUrl
                }
            }).then(res => {
                this.$message.success('修改成功')
                this.loading1 = false
                this.faceSnap.equipmentServe = false
            }).catch(err => {
                this.loading1 = false
                this.faceSnap.equipmentServe = false
                this.isError(err, this)
            })
        },
        emptyFaceConfirm() { //清空脸库
            this.loading1 = true
            this.$axios({
                method: 'delete',
                url: `/common2/facePersons/faceClear`,
                params: {
                    deviceId: this.form.clickrow.jourDeviceId
                }
            }).then(res => {
                this.$message.success('清空成功')
                this.loading1 = false
                this.faceSnap.emptyFace = false
            }).catch(err => {
                this.loading1 = false
                this.faceSnap.emptyFace = false
                this.isError(err, this)
            })
        },
        modifyPushAddress() {
            this.modifyPush.dialog = true
            this.$axios({
                method: "post",
                url: `/common2/devices/modifyPushAddr`,
                params: {
                    deviceId: this.enabled.params.devId,
                    type: '0'
                }
            })
            .then(res => {
                this.modifyPush.url = res.data.result.data.HTTPServer
            })
            .catch(err => {
                this.isError(err, this);
            })
        },
        deleteFaceDevice() {
            this.$confirm('此操作将删除该设备及其数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "delete",
                    url: `/common2/devices/deleteFaceDevice`,
                    params: {
                        deviceId: this.enabled.params.devId,
                        serverUrl: null
                    }
                })
                .then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.dialogCaoZuoSheBei = false
                    this.modifyPush.dialog = false
                    this.form.cursor = 1
                    this.getAllSheBei()
                })
                .catch(err => {
                    this.isError(err, this);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        remoteDoorOpening() { //远程开门
            this.loading1 = true
            this.$axios({
                method: 'get',
                url: `/common2/devices/openDoor/${this.form.clickrow.jourDeviceId}`,
            }).then(res => {
                this.$message.success('开门成功')
                this.loading1 = false
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        faceSnapSave() { //多人注册人脸保存
            this.loading1 = true
            this.$axios({
                method: 'post',
                url: `/common2/facePersons/manyPersonFaceRegistration`,
                params: {
                    deviceId: this.form.clickrow.jourDeviceId,
                    persons: JSON.stringify(this.faceSnap.checkedFace)
                }
            }).then(res => {
                this.faceSnap.faceDialogs = false
                this.$message.success('授权成功')
                this.loading1 = false
            }).catch(err => {
                this.faceSnapFun()
                this.loading1 = false
                this.isError(err, this)
            })
        },
        deleteFaceAuthorization(row) { //删除授权请求
            this.loading1 = true
            this.$axios({
                method: 'delete',
                url: `/common2/facePersons/${row.ifpId}/deviceFaceDelete`,
                params: {
                    deviceId: this.form.clickrow.jourDeviceId
                }
            }).then(res => {
                this.$message.success('删除成功')
                this.haveAuthorized()
                this.loading1 = false
            }).catch(err => {
                this.loading1 = false
                this.isError(err, this)
            })
        },
        snapClose() { //抓拍记录窗口关闭
            this.faceSnap.time = ''
            this.faceSnap.jftiPersonName = ''
        },
        snapCurrent(cursor) { //分页
            this.faceSnap.cursor = cursor
            this.captureRecordsFun()
        },
        BlackListDialog() { // 黑名单
            this.blackList.dialog = true,
            this.getDatablackList()
        },
        addBlackListDialog() { //添加黑名单
            this.blackList.dialog1 = true
            this.changeStaffType()
            // this.getStaffList()
        },
        getStaffList(){ //获取内部员工列表信息
            this.loading1 =true
            this.$axios({
                method:'get',
                url:`common2/devices/getUnauthorizedPersonnel/1417`,
                params:{
                    getType:'黑名单'
                }
            }).then(res =>{
                this.blackList.staffList = res.data.result
                this.blackList.staffList.forEach(item =>{
                    item.ifpName = `${item.ifpName}----${item.ifpPersonType}`
                })
                console.log(this.blackList.staffList)
                this.loading1 = false
            }).catch(err=>{
                this.loading1 = false
                this.isError(err, this);
            })
        },

        
        changeStaffType(){
            this.blackList.staffType = "外部人员"
            this.blackList.staffListDialogVisible = false
        },
        
        addblackListPrevious() { // 添加黑名单上一步
            if(this.blackList.active>1){
                this.blackList.active--
            }
        },
        addblackListNext() { // 添加黑名单下一步
            if (this.submitForm(['blackList'], this)) {
                if(this.blackList.active<3){
                   if(this.blackList.active == 1) {
                       this.uploadBlackList()
                   }else if(this.blackList.active == 2){
                       if (!this.blackList.base64[0]) {
                            this.$message.error('请上传图片!!')
                            return
                        }else{
                            this.blackList.active++
                            this.getEquipmentList() //获取枪机列表
                        }
                   }
                }                 
            }
        },

        changeStaffList(){ //添加内部员工到黑名单
            this.blackList.staffListDialogVisible = true
            this.getStaffList()
        },

        cancelUploadBlackStaff(){
            this.blackList.dialogVisibleBlackStaff = false
        },

        confirmUploadBlackStaffList(){
            if(!this.blackList.checkedStaffList.length){
                this.$message.error("请至少选择一位员工")
            }else{
                this.blackList.dialogVisibleBlackStaff = true
            }
        },

        uploadBlackStaffList(){ //上传内部人员的黑名单列表
            console.log(this.form.clickrow.jourDeviceId+"测试")
            this.loading1 = true
            this.$axios({
                method:'post',
                url:`/common2/facePersons/manyPersonFaceRegistration`,
                params:{
                    deviceId: this.form.clickrow.jourDeviceId,
                    persons:JSON.stringify(this.blackList.checkedStaffList),
                    listType: '黑名单'
                }
            }
            ).then(res =>{
               console.log(this.blackList.checkedStaffList +"????")
               this.blackList.checkedStaffList = []
               this.blackList.dialogVisibleBlackStaff = false
               this.getStaffList()
               this.$message.success('添加黑名单成功')
               this.loading1 = false
            }).catch(err =>{
                this.blackList.dialogVisibleBlackStaff = false
                this.getStaffList()
                this.loading1 = false
                this.isError(err,this)
            })
        },

        getDatablackList() { //获取黑名单列表数据
            this.loading1 = true,
            this.$axios({
                    method:'get',
                    url: `/common2/devices/getAuthorizedPersonnel/${this.form.clickrow.jourDeviceId}`,
                    params: {
                        type:'黑名单'
                    }
                }).then(res=>{
                    this.blackList1.params = res.data.result
                    // this.blackList1.total = res.data.result.total
                    // this.blackList1.pages = res.data.result.total/this.blackList1.limit
                    this.loading1 = false
                    for(let i of this.blackList1.params){
                        i.image = JSON.parse(i.ifpGuidPhoto)[0].faceUrl
                    }
                }).catch(err => {
                    this.isError(err, this);
                    this.loading1 = false
            })
            
        },

        removeBlackUser(row){ //删除黑名单人员
            this.$axios({
                method: 'delete',
                url:`/common2/facePersons/${row.ifpId}/deviceFaceDelete`,
                params:{
                    deviceId: this.form.clickrow.jourDeviceId,
                    blackType:"黑名单"
                }
            }).then(res =>{
                this.$message.success('删除成功')
                this.getDatablackList()
            }).catch(err =>{
                this.isError(err,this)
            })
        },


        blackListSizeChange(size){  // 每页条数发生改变
            this.blackList1.limit = size
            this.getDatablackList()
        },

        blackListCurrentchange(cursor){  //跳转页面
            this.blackList1.cursor = cursor
            this.getDatablackList()
        },
        blackListNextClick(cursor){ 
            this.blackList1.cursor = cursor
            this.getDatablackList()
        },
        blackListPrevClick(cursor){
            this.blackList1.cursor = cursor
            this.getDatablackList()
        },

        restoreblackList() {  //重置添加黑名单
            this.beforeRemove()
            this.blackList = {
                dialog1: false,
                dialog: false,
                blackSex: '男',
                personnelAscription: '',
                active: 1,
                base64:[],
                blackName:'',
                personnelId:''
            }
        },

        addblackListClose() { // 添加黑名单关闭
            if(this.blackList.personnelId){
                this.cancelUpload()
            }
            this.blackList.dialog1 = false
        },

        uploadBlackList(){ //添加黑名单步骤1
            this.loading1 = true,
            this.$axios({
                method: 'post',
                url: `/common2/users/addBlackPeople`,
                data:{
                    personnelType: '外部人员',
                    blackName:this.blackList.blackName,
                    blackSex: this.blackList.blackSex,
                    blackRemarks: this.blackList.personnelAscription,
                    blackType:"黑名单"
                }
            }).then((res)=>{
                if(res.data.status == 200){
                    console.log("上传成功!!")
                    console.log(res.data.result.personnelId)
                    this.blackList.personnelId = res.data.result.personnelId
                    this.loading1 = false
                    this.blackList.active++
                }
            }).catch((err) => {
                this.isError(err, this);
                this.loading1 = false
            });
        },

       cancelUpload(){ //取消上传删除黑名单数据
            if(!this.blackList.personnelId){
                this.blackList.dialog1 = false
                return 
            }else{
                 this.loading1 = true
                if(!this.blackList.base64.length){ //判断是否有图片的取消操作
                        this.$axios({
                        method: 'delete',
                        url:`/common2/users/deleteBlackPeople/${this.blackList.personnelId}`
                    }).then((res)=>{
                        this.restoreblackList()
                        this.$message({
                            message:'已取消上传黑名单!',
                            type:'primary'
                        });
                        this.loading1 = false
                    }).catch((err)=>{
                        this.isError(err,this);
                        this.loading1 = false
                    })
                }else{
                    this.$axios({
                        method:'delete',
                        url:`/common2/facePersons/${this.blackList.personnelId}/pictureDelete`,
                        params:{
                            personnelType:"外部人员",
                            blackType:"黑名单"
                        },
                    }).then((res=>{
                        if(res.data.status == 200){
                            this.restoreblackList()
                            this.$message({
                                type:'primary',
                                message:'已取消上传黑名单'
                            })
                        this.loading1 = false
                        }
                    })).catch((err)=>{
                        this.isError(err,this);
                        this.loading1 = false
                    })
                }
            }
              
        },

        handlePictureCardPreview(file){//查看某张图片的原图
            this.blackList.base64[0] = file.url;
            this.blackList.dialogVisible = true;
        },
        beforeRemove(){
            return true
        },
        handleRemove(file, fileList) { //删除图片
            console.log(this.blackList.personnelId);
            this.$axios({
                method:'delete',
                url:`/common2/facePersons/${this.blackList.personnelId}/pictureDelete`,
                params:{
                    personnelType:"外部人员"
                },
            }).then((res=>{
                if(res.data.status == 200){
                    this.blackList.base64 = []
                    this.$message({
                        type:'primary',
                        message:'取消上传图片成功'
                    })
                }
            })).catch((err)=>{
                this.isError(err,this);
            })
        },
        openPicture(url){
            this.blackList1.dialogImageUrl = url
            this.blackList1.dialogVisible = true
        },

        enlargePictureCloseList(){
            this.blackList1.dialogVisible = false 
        },

        enlargePictureClose(){
            this.blackList.dialogVisible = false //关闭原图
        },

         blackListSave() { // 添加上传黑名单图片
            
            this.loading1 = true
            this.$axios({
                method: 'post',
                url: `/common2/facePersons/pictureUpload/`,
                data: {
                    personnelId:this.blackList.personnelId,
                    base64: this.blackList.base64[0],
                    personnelType: '外部人员'
                }
            }).then((res) => {
               if(res.data.status == 200){
                this.blackList.dialog = false
                this.$message.success('添加成功')
               }else{
                 this.blackList.dialog = false
                 this.$message.success('添加失败')
                 this.blackList.base64 = [] 
               }
            }).catch((err) => {
                this.isError(err, this);
                
            }).finally(() => {
                this.loading1 = false
            })
        },

        getEquipmentList(){ //获取黑名单绑定的枪机列表
            this.loading1 = true
            this.$axios({
                method: 'get',
                url:`/common2/devices/faces`,
                params:{
                   userId: this.blackList.personnelId,
                   state:'不绑定',
                   personType:'用户',
                   devNickname:'',
                   devSn:'',
                   address:''
                },
            }).then(res =>{
                this.loading1 = false
                this.blackList.equipmentList = res.data.result
                console.log( this.blackList.equipmentList)

            }).catch(err =>{
                this.loading1 = false
                this.isError(err, this)
            })
        },
        selectFaceDevice(row){
            this.blackList.selectedFaceDevice = row
            console.log(this.blackList.selectedFaceDevice)
        },

        uploadFaceDevcieList(){ //上传与绑定的枪机列表 
            this.loading1 = true
            let selectedFaceDeviceId = []
            for (let i = 0;i< this.blackList.selectedFaceDevice.length;i++){
                selectedFaceDeviceId.push(this.blackList.selectedFaceDevice[i].jourDeviceId)
            }
            if(!this.blackList.selectedFaceDevice.length){
                this.$message({
                    message:'请至少绑定一个设备',
                    type:'warning'
                })
            }else{
                this.$axios({
                    method: 'post',
                    url:`/common2/facePersons/faceRegistration`,
                    data:{
                        personnelId:this.blackList.personnelId,
                        personnelType:'外部人员',
                        deviceIds: selectedFaceDeviceId,
                        blackType:'黑名单'
                    }
                }).then((res)=>{
                    this.loading1 = false
                     this.restoreblackList()
                        console.log(res.data.result)
                        this.$message({
                            message:'上传成功',
                            type:'success'
                        })
                }).catch((err)=>{
                    this.loading1 =false
                    this.isError(err,this)
                })
            }
        },


        getFile(file, fileList){//图片上传本地
            console.log(file,fileList)
            let testmsg = file.name.substring(file.name.lastIndexOf('.')+1)
            const extension = testmsg === 'jpg'
            const extension2 = testmsg === 'png'
            if(!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 jpg、png格式!',
                    type: 'warning'
                });
                return
            }
            this.getBase64(file.raw).then(res => {
                this.blackList.base64[0] = res[0]
                this.blackListSave()
            });
            
        },
        getBase64(file) {//转base64
            let reader = new FileReader();
            let imgResult = "";
            reader.readAsDataURL(file);
            let img = new Image();
            reader.onload = function (e) {
                img.src = e.target.result;
            }
            let imgP = new Promise ((resolve, reject) => {
                img.onload = () => {
                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext('2d');
                    // 瓦片 canvas
                    let tCanvas = document.createElement('canvas');
                    let tctx = tCanvas.getContext('2d');
                    let initSize = img.src.lengtth;
                    let width = img.width;
                    let height = img.height;
                    // 图片像素大于400万像素，计算压缩到400万以下
                    let ratio;
                    if ((ratio = width * height / 4000000) > 1) {
                        ratio = Math.sqrt(ratio);
                        width /= ratio;
                        height /= ratio;
                    } else {
                        ratio = 1;
                    }
                    canvas.width = width;
                    canvas.height = height;
                    ctx.fillStyle = '#FFF';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    // 如果图片太大则使用瓦片绘制
                    let count;
                    if ((count = width * height / 1000000 > 1)) {
                        count = ~~(Math.sqrt(count) + 1); // 计算分成的瓦片数
                        let nw = ~~(width / count);
                        let nh = ~~(height / count);
                        tCanvas.width = nw;
                        tCanvas.height = nh;
                        for (let i = 0; i < count; i ++) {
                            for (let j = 0; j < count; j ++) {
                                tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
                                ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                            }
                        }
                    } else {
                        ctx.drawImage(img, 0, 0, width, height);
                    }
                    // 进行最小压缩
                    let ndata = canvas.toDataURL('image/jpeg', 0.3);
                    tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
                    resolve(ndata);
                }
            })
            return Promise.all([imgP])
        },
        test() {
            console.log('test')
        },

    }
}
</script>